import Hero from 'components/Hero/Hero'
import { NoHeaderLayout }  from 'components/Layout/Layout'
import Steps from "components/Steps/Steps";
import ServicesList from "components/ServicesList/ServicesList";
import GlowTitle from "components/GlowTitle/GlowTitle";
import CompanyDescription from "components/CompanyDescription/CompanyDescription";

import { getServices } from 'data/cms'
import { useQuery } from 'react-query'

import "./Home.scss";

const Home = () => {
  const { isLoading, data } = useQuery('services', getServices);

	return (
		<div className="home-page">
      <Hero />
			<NoHeaderLayout>
        <>
          
          <GlowTitle id="inpersonpgdate" className="home-page__glow-title" title="OPEN ROLES" />
          { isLoading ? <span>Loading...</span> : 
            <>
              <ServicesList className="home-page__services-list" capabilities={data.inperson_services} ctatext={data.services_apply_cta_text} />
            </>
          }
          <CompanyDescription />    
        </>
			</NoHeaderLayout>
      
		</div>
	)
}

export default Home
