import { Container, Row } from "react-bootstrap";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

import "./Layout.scss";

type LayoutProps = {
	children: JSX.Element | JSX.Element[];
};

export const Layout = ({ children }: LayoutProps) => {
	return (
		<Container fluid>
			<Header />
			<Row>
				<Container>{children}</Container>
			</Row>
			<Footer />
		</Container>
	);
};

export const NoHeaderLayout = ({ children }: LayoutProps) => {
	return (
		<Container fluid>
			<Row>
				<Container>{children}</Container>
			</Row>
			<Footer />
		</Container>
	);
};

export default Layout;
