import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Switch, Route, Redirect } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from 'react-query'
import { createBrowserHistory } from 'history'

import Home from './containers/Home/Home'
import ContactForm from './containers/ContactForm/ContactForm'
import { Thanks } from './containers/Thanks/Thanks'
import MediaTest from 'containers/MediaTest/MediaTest'
import MediaTestList from 'containers/MediaTestList/MediaTestList'
import reportWebVitals from './reportWebVitals'

import './index.scss'
import { Media } from 'react-bootstrap'
import ScrollToTop from 'components/ScrollToTop/ScrollToTop'
import { Tracking } from 'components'

if (process.env.REACT_APP_MOCKS_ENABLED === 'true') {
  const { worker } = require('./mocks/browser')
  worker.start()
}

// Create a client
const queryClient = new QueryClient()

let historyProvider = createBrowserHistory()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router history={historyProvider}>
        <ScrollToTop>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
        </ScrollToTop>
        <Tracking />
      </Router>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
