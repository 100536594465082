

const CompanyDescription = () => {

    return (
        <>
            <p>
                Publicis Global Delivery is the talent powerhouse of Publicis Groupe, the largest global
                communications group. We make sure to hire, boost and develop the best people worldwide to
                deliver outstanding work for the most prominent clients within the Groupe.
            </p>
            <p>
                In LATAM, we are over 2,500 passionate employees that love to push boundaries and drive
                innovative solutions. If you are a risk-taker and love to develop intrepid ideas, PGD is the place
                for you.
            </p>
            <p>
                We Move People, and People Move Us!
            </p>
            <br />
        </>

    );

}

export default CompanyDescription;