import { Link } from 'react-router-dom'
import './ServicesList.scss'
import { useAnalytics } from 'hooks/useAnalytics'

type ServiceListProps = {
  className?: string,
  capabilities: ServicesListItemProps[],
  ctatext: string
}

type ServicesListItemProps = {
  index: number,
  overline: string,
  title: string,
  description: string,
  level1_label: string,
  level1_requiredList: string[],
  level2_label: string,
  level2_requiredList: string[],
  ctatext: string,
  position_url: string
}

const ServicesList = (props : ServiceListProps) => {

  const renderServicesList = () => {
    return props.capabilities.map((item, index) => {
      return (
        <ServicesListItem key={index} {...item} index={index} ctatext={props.ctatext} />
      )
    })
  }

  return (
    <div className={`services-list ${props.className}`}>
      { renderServicesList() }
    </div>
  )
}

const ServicesListItem = (props:ServicesListItemProps) => {

  const { trackEvent } = useAnalytics();
  
  const renderLevel1RequiredList = () => {
    return props.level1_requiredList.map((item, index) => {
      return (
        <li className="services-list-item__capabilities-list-item" key={index}>- { item }</li>
      )
    })
  }

  const renderLevel2RequiredList = () => {
    return props.level2_requiredList.map((item, index) => {
      return (
        <li className="services-list-item__capabilities-list-item" key={index}>- { item }</li>
      )
    })
  }

  return (
    <div className="services-list-item">
      <p className="services-list-item__title">{ props.title }</p>
      <p className="services-list-item__overline">{ props.description }</p>
      <div className="services-list-item__content">
        
        <span className="services-list-item__subtitle">{ props.level1_label }</span>
        <br />
        <ul className="services-list-item__capabilities-list">
          { renderLevel1RequiredList() }
        </ul>
        <br />
        { props.level2_label ? (
            <>
              <span className="services-list-item__subtitle">{ props.level2_label }</span>
              <br />
              <ul className="services-list-item__capabilities-list">
                { renderLevel2RequiredList() }
              </ul>
              <br />
            </>
            
          ) : null}
       
        <a className="services-list-item__apply-btn btn btn-outline-primary-secondary" href={props.position_url} target="_blank">{ props.ctatext }</a>
      </div>
    </div>
  ) 
}

export default ServicesList
