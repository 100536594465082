import { client } from './client';

const cmsBaseUrl = process.env.REACT_APP_CMS_URL;

export function getHomepage() {
  return client(`${cmsBaseUrl}/homepage.json`); 
}

export function getSteps() {
  return client(`${cmsBaseUrl}/steps.json`); 
}

export function getServices() {
  return client(`${cmsBaseUrl}/services.json`); 
}

export function getTests() {
  return client(`${cmsBaseUrl}/tests.json`); 
}

export function getFooter() {
  return client(`${cmsBaseUrl}/footer.json`); 
}

export function getForm() {
  return client(`${cmsBaseUrl}/form.json`); 
}

export function getTest(discipline:string) {
  return client(`${cmsBaseUrl}/tests/${discipline}`);
}