import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import {
	facebook,
	facebook_2x,
	facebook_3x,
	footer_bg,
	instagram,
	instagram_2x,
	instagram_3x,
	linkedin,
	linkedin_2x,
	linkedin_3x,
	prodigious_logo,
	prodigious_logo_2x,
	prodigious_logo_3x,
	publicis_media_logo,
	publicis_media_logo_2x,
	publicis_media_logo_3x,
	publicis_logo,
	publicis_logo_2x,
	publicis_logo_3x,
	social_tiktok,
	social_tiktok_2x,
	social_tiktok_3x,
	social_youtube,
	social_youtube_2x,
	social_youtube_3x,
} from "assets/images";

import { getFooter } from 'data/cms'
import { useQuery } from 'react-query'

import "./Footer.scss";
import { useAnalytics } from "hooks/useAnalytics";

const Footer = () => {

	const { trackEvent } = useAnalytics();
	const { isLoading, data } = useQuery('footer', getFooter);

	return (
		<>
			<Row
				className="footer"
				style={{ backgroundImage: `url(${footer_bg})` }}
			>
				<Container className="footer__wrapper">
					{ isLoading ? <span>Loading...</span> :
						<>
							<Row>
								<Col>
									<div className="footer__row">
										<div className="footer__logos">
											<img
												className="footer__publicis-media-logo"
												srcSet={`${publicis_logo_3x} 3x, ${publicis_logo_2x} 2x, ${publicis_logo} 1x`}
												src={publicis_logo}
												alt="Publicis Logo"
											/>
											{/* <div className="footer__divider"></div>
											<img
												className="footer__prodigious-logo"
												srcSet={`${prodigious_logo_3x} 3x, ${prodigious_logo_2x} 2x, ${prodigious_logo} 1x`}
												src={prodigious_logo}
												alt="Prodigious Logo"
											/> */}
										</div>
									</div>
								</Col>
							</Row>
							<Row>
								{/* <Col xs={12} sm={9} lg={10}>
									<Row className="mb-4">
										<Col xs={12} sm={7} className="mb-4">
											<div className="footer__location-colombia">
												<p>Colombia</p>
												<p>
													{data.colombia_phone} - <a id="COmail_footer" style={{ color: "white" }} href={`mailto:${data.colombia_email}`} onClick={() => trackEvent({category: 'Hyperlink', action: 'Mail', label: 'CO' })}>{data.colombia_email}</a>
												</p>
											</div>
										</Col>
										<Col xs={12} sm={5} className="mb-4">
											<div className="footer__location-costa-rica">
												<p>Costa Rica</p>
												<p>
													{data.costa_rica_phone} - <a id="CRmail_footer" style={{ color: "white" }} href={`mailto:${data.costa_rica_email}`} onClick={() => trackEvent({category: 'Hyperlink', action: 'Mail', label: 'CR' })}>{data.costa_rica_email}</a>
												</p>
											</div>
										</Col>
									</Row>
								</Col> */}
								<Col xs={12} sm={5} md={4} lg={3}>
									<div className="footer__social-links">
										<a id="IG_Footer" href={data.instagram_url} target="_blank" onClick={() => trackEvent({category: 'Footer', action: 'Social', label: 'IG' })}>
											<img
												srcSet={`${instagram_3x} 3x, ${instagram_2x} 2x, ${instagram} 1x`}
												src={instagram}
												alt="Instagram Logo"
											/>
										</a>

										<a id="FB_Footer" href={data.facebook_url} target="_blank" onClick={() => trackEvent({category: 'Footer', action: 'Social', label: 'FB' })}>
											<img
												srcSet={`${facebook_3x} 3x, ${facebook_2x} 2x, ${facebook} 1x`}
												src={facebook}
												alt="Facebook Logo"
											/>
										</a>
										<a id="YT_Footer" href={data.youtube_url} target="_blank" onClick={() => trackEvent({category: 'Footer', action: 'Social', label: 'YT' })}>
											<img
												srcSet={`${social_youtube_3x} 3x, ${social_youtube_2x} 2x, ${social_youtube} 1x`}
												src={social_youtube}
												alt="YouTube Logo"
											/>
										</a>
										<a id="IN_Footer" href={data.linkedin_url} target="_blank" onClick={() => trackEvent({category: 'Footer', action: 'Social', label: 'IN' })}>
											<img
												srcSet={`${linkedin_3x} 3x, ${linkedin_2x} 2x, ${linkedin} 1x`}
												src={linkedin}
												alt="LinkedIn Logo"
											/>
										</a>
										<a id="TT_Footer" href={data.tiktok_url} target="_blank" onClick={() => trackEvent({category: 'Footer', action: 'Social', label: 'TT' })}>
											<img
												srcSet={`${social_tiktok_3x} 3x, ${social_tiktok_2x} 2x, ${social_tiktok} 1x`}
												src={social_tiktok}
												alt="TikTok Logo"
											/>
										</a>

									</div>
								</Col>
							</Row>
						</>
					}

				</Container>
			</Row>
		</>
	);
};

export default Footer;
