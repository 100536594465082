import "./GlowTitle.scss";

type GlowTitleProps = {
	className?: string,
	title: string,
	id: string
};

export const GlowTitle = ({ className, title, id }: GlowTitleProps) => {
	return (
		<h1 className={`text-glow ${className}`} id={id}>
			<div className="text-glow__shadow text-glow__shadow--1">
				<div>{title}</div>
			</div>
			<div className="text-glow__shadow text-glow__shadow--2">
				<div>{title}</div>
			</div>
			<div>{title}</div>
			<div className="text-glow__shadow text-glow__shadow--3">
				<div>{title}</div>
			</div>
			<div className="text-glow__shadow text-glow__shadow--4">
				<div>{title}</div>
			</div>
		</h1>
	);
};

export default GlowTitle;
