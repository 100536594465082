import { Container, Row } from "react-bootstrap"
import {
	// prodigious_logo,
	// prodigious_logo_2x,
	// prodigious_logo_3x,
	// publicis_media_logo,
	// publicis_media_logo_2x,
	// publicis_media_logo_3x,
	pgd_logo,
	pgd_logo_2x,
	pgd_logo_3x,
	pgdate_logo
} from "assets/images"

import "./Header.scss"

const Header = () => {
	return (
		<Row>
			<Container>
				<div className="header">
					<img
						className="header__publicis-media-logo"
						srcSet={`${pgd_logo_3x} 3x, ${pgd_logo_2x} 2x, ${pgd_logo} 1x`}
						src={pgd_logo}
						alt="PGD Logo"
					/>
					<img
						className="header__pgdate-logo"
						srcSet={`${pgdate_logo} 3x, ${pgdate_logo} 2x, ${pgdate_logo} 1x`}
						src={pgdate_logo}
						alt="PGDate Logo"
					/>
				</div>
			</Container>
		</Row>
	)
}

export default Header
