import { Button, Container, Row } from "react-bootstrap";
import { scroller } from 'react-scroll';
import Header from "components/Header/Header";
import HeroBackgroundImg from "assets/images/pgdate.png";
import "./Hero.scss";

import { getHomepage } from 'data/cms'
import { useQuery } from 'react-query'
import { useAnalytics } from "hooks/useAnalytics";
import DOMPurify from 'dompurify';



const Hero = () => {

	const { trackEvent } = useAnalytics();
	const { isLoading, data } = useQuery('homepage', getHomepage);

	const sanitizedDataHero1 = () => ({
		__html: DOMPurify.sanitize(data !== undefined ? data.hero_text : "")
	});
		
	const sanitizedDataHero2 = () => ({
		__html: DOMPurify.sanitize(data !== undefined ? data.hero_text2 : "")
	});


	return (
		<div className="hero">

			<div className="hero__overlay">
				<Container fluid>
					<Row>
						<Container>
							<div className="hero__content">
								<Header />
							</div>
						</Container>
					</Row>
				</Container>
			</div>
			<div className="hero__overlay hero__overlay--description">

				<Container fluid>
					<Row>
						<Container>
							<div className="hero__content">
								<div className="hero__description">
									{ isLoading ? <span>Loading...</span> : 
										<>
											<p className="hero__text" dangerouslySetInnerHTML={sanitizedDataHero1()}>
											</p>
											<p className="hero__text" dangerouslySetInnerHTML={sanitizedDataHero2()}>
											</p>
											<Button
												id={"Apply_CTA_1"}
												variant="outline-primary-secondary"
												onClick={() => {
													trackEvent({category: 'CTA', action: 'Apply', label: 'Positions In Person' });
													scroller.scrollTo('inpersonpgdate', {
														smooth: true,
														offset: -50,
														duration: 500
													})
												}}
											>
												{data.hero_inperson_cta_text}
											</Button>
										</>
									}
								</div>
							</div>
						</Container>
					</Row>
				</Container>
			</div>
		</div>
	)
}

export default Hero
