import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import ReactGA from "react-ga";
import useScrollTracker from "hooks/useScrollTracker";



const Tracking = () => {

    const history = useHistory();
    const GA_Account = process.env.REACT_APP_GA_ACCOUNT;

    useScrollTracker([25, 50, 75, 100], ({ scrollY }) => {
        ReactGA.event({ category: 'Scroll', action: `${scrollY}pct`, label: `${scrollY}pct` })
        // ReactGA.ga('send', 'scroll depth reached:', scrollY)
    });
    
    useEffect(() => {
        if (GA_Account)
        {    
            console.log(`Initializing tracking ${GA_Account}`);
            ReactGA.initialize(GA_Account);
            ReactGA.pageview(window.location.href);

            return history.listen((location) => { 
                console.log(`Sending location change: ${location.pathname}`);
                ReactGA.ga("set", {
                    title: document.title,
                    page: location.pathname + location.search,
                    location: window.location.href
                });
                ReactGA.ga("send", { hitType: "pageview" });
            });
        }
        else
        {
            console.log('No GA account found');
        }
    },[history, GA_Account]);

    const onScroll = () => {

    }

    return (
    <></>
    );
};

export default Tracking;