// @ts-nocheck
const localStorageKey = 'token'
export function client(endpoint: string, { body, contentType } = {}) {
  const token = window.localStorage.getItem(localStorageKey)
  const headers = contentType ? { 'content-type': contentType } : {};
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  const config = {
    method: body ? 'POST' : 'GET',
    headers: {
      ...headers
    },
  }
  if (body) {
    config.body = body
  }
  return window
    .fetch(endpoint, config)
    .then(async (response) => {
      if (response.status === 401) {
        logout()
        window.location.assign(window.location)
        return
      }
      if (response.ok) {
        return await response.json()
      } else {
        const errorMessage = await response.text()
        return Promise.reject(new Error(errorMessage))
      }
    })
}
export function logout() {
  window.localStorage.removeItem(localStorageKey)
}
